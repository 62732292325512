import Experience from "../../Experience"
import { gsap } from 'gsap'
import ScrollEvent from "../ScrollEvent"

export default class CardsScrollEvents {
    constructor({ prefix = 'work', render }) {
        this.prefix = prefix
        this.render = render

        let header1 = document.querySelectorAll('.' + this.prefix + '-section-header-container');
        let header0 = document.querySelector('.' + this.prefix + '-section-header-container');

        this.domElements = {
            aboutSection: document.getElementById('about-section'),
            smallHeader: document.querySelector('.section-subheader-container'),
            header0: header0,
            header1: header1.length > 1 ? header1[1] : header0,
            cards: document.querySelectorAll('.' + this.prefix + '-item-container'),
            renderContainer: document.getElementById(this.prefix + '-render-container'),
            navigation: document.getElementById(this.prefix + '-navigation-container')
        }

        this.experience = new Experience()
        this.sizes = this.experience.sizes

        this.addScrollEvents()
    }

    resetPositions() {
        this.scrollEvents.forEach(event => {
            if (event.reset)
                event.reset()
        })
    }

    addScrollEvents() {
        if (!this.played) {
            this.scrollEvents = [
                // Small Header
                new ScrollEvent({
                    element: this.domElements.smallHeader,
                    direction: 'down',
                    f: () => {
                        gsap.to(this.domElements.smallHeader, { y: 0, opacity: 1, duration: .3 })
                    },
                    setup: () => gsap.to(this.domElements.smallHeader, { y: 100, opacity: 0, duration: 0 }),
                    reset: () => gsap.to(this.domElements.smallHeader, { y: 0, opacity: 1, duration: 0 }),
                }),

                //Headers
                new ScrollEvent({
                    element: this.domElements.header0,
                    direction: 'down',
                    f: () => gsap.to(this.domElements.header0, { y: 0, opacity: 1, duration: .4 }),
                    setup: () => gsap.to(this.domElements.header0, { y: 100, opacity: 0, duration: 0 }),
                    reset: () => gsap.to(this.domElements.header0, { y: 0, opacity: 1, duration: 0 })
                }),

                new ScrollEvent({
                    element: this.domElements.header1,
                    direction: 'down',
                    f: () => gsap.to(this.domElements.header1, { y: 0, opacity: 1, duration: .5 }),
                    setup: () => gsap.to(this.domElements.header1, { y: 100, opacity: 0, duration: 0 }),
                    reset: () => gsap.to(this.domElements.header1, { y: 0, opacity: 1, duration: 0 })
                }),

                //Cards
                new ScrollEvent({
                    element: this.domElements.cards[Math.ceil((this.render.items.length) / 2)],
                    direction: 'down',
                    f: () => {
                        //default opacity
                        for (let i = 0; i < (this.render.items.length - 1); i++) {
                            gsap.to(this.domElements.cards[i], { y: 0, opacity: 1, duration: .85 })
                        }

                        //custom opacity
                        gsap.to(this.domElements.cards[1], { y: 0, opacity: 1, duration: .75 })
                        gsap.to(this.domElements.cards[2], { y: 0, opacity: 1, duration: .65 })
                        gsap.to(this.domElements.cards[3], { y: 0, opacity: 1, duration: .75 })

                        //last element opacity
                        gsap.to(this.domElements.cards[(this.render.items.length - 1)], {
                            y: 0, opacity: 1, duration: .85, onComplete: () => {
                                gsap.delayedCall(.2, () => {
                                    this.addTransitionClass(true)
                                    this.played = true
                                })
                            }
                        })
                    },
                    setup: () => {
                        this.addTransitionClass(false)

                        for (let i = 0; i < this.render.items.length; i++) {
                            gsap.to(this.domElements.cards[i], { y: 100, opacity: 0, duration: 0 })
                        }
                    },
                    reset: () => {
                        for (let i = 0; i < (this.render.items.length - 1); i++) {
                            gsap.to(this.domElements.cards[i], { y: 0, opacity: 1, duration: 0 })
                        }

                        gsap.to(this.domElements.cards[(this.render.items.length - 1)], {
                            y: 0, opacity: 1, duration: 0, onComplete: () => {
                                this.addTransitionClass(true)
                                this.played = true
                            }
                        })
                    }
                }),
            ]
        }
    }

    addTransitionClass(addOrRemove) {
        this.domElements.cards.forEach((card) => {
            addOrRemove ? card.classList.add(this.prefix + '-item-container-transition') : card.classList.remove(this.prefix + '-item-container-transition')
        })
    }

    resize() {
        setTimeout(() => this.addScrollEvents())
    }
}