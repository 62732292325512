export default {
  'work': {
    html: `<div class="work-item-tag" style="background: white; border: 1px solid #7C8594; color: #7C8594">HTML</div>`,
    css: `<div class="work-item-tag" style="background: white; border: 1px solid #7C8594; color: #7C8594">CSS</div>`,
    javascript: `<div class="work-item-tag" style="background: #FFB800;">JavaScript</div>`,
    socketio: `<div class="work-item-tag" style="background: #21BAEB;">Socket.IO</div>`,
    webgl: `<div class="work-item-tag" style="background: #5A69EC;">WebGL</div>`,
    api: `<div class="work-item-tag" style="background: #CA49F8;">API</div>`,
    backend: `<div class="work-item-tag" style="background: #8433CC;">Backend</div>`,
  },
  'books': {
    html: `<div class="books-item-tag" style="background: white; border: 1px solid #7C8594; color: #7C8594">HTML</div>`,
    css: `<div class="books-item-tag" style="background: white; border: 1px solid #7C8594; color: #7C8594">CSS</div>`,
    javascript: `<div class="books-item-tag" style="background: #FFB800;">JavaScript</div>`,
    socketio: `<div class="books-item-tag" style="background: #21BAEB;">Socket.IO</div>`,
    webgl: `<div class="books-item-tag" style="background: #5A69EC;">WebGL</div>`,
    api: `<div class="books-item-tag" style="background: #CA49F8;">API</div>`,
    backend: `<div class="books-item-tag" style="background: #8433CC;">Backend</div>`,
  }
};
