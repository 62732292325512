export default {
  'clients': [
    {
      id: 0,
      name: "Harpaceas",
      description: "Harpaceas",
      image: "images/clients/harpaceas.png",
      tags: [],
      twitter: "",
      alt: "Harpaceas"
    },
    {
      id: 1,
      name: "Lucca Crea",
      description: "Lucca Crea",
      image: "images/clients/lucca.png",
      tags: [],
      twitter: "Lucca Crea",
      alt: "Lucca Crea"
    },
    {
      id: 2,
      name: "Criminologia",
      description:
          "Criminologia",
      image: "images/clients/criminologia.png",
      tags: [],
      twitter: "Criminologia",
      alt: "Criminologia"
    },
    {
      id: 3,
      name: "Azimut",
      description: "Azimut",
      image: "images/clients/azimut.png",
      tags: [],
      twitter: "Azimut",
      alt: "Azimut"
    },
    {
      id: 4,
      name: "Gruppo Santelli",
      description: "Gruppo Santelli",
      image: "images/clients/santelli.png",
      tags: [],
      twitter: "Gruppo Santelli",
      alt: "Gruppo Santelli"
    },
    {
      id: 5,
      name: "Com Due",
      description: "Com Due",
      image: "images/clients/comdue.png",
      tags: [],
      twitter: "https://algoretico.it/",
      alt: "Com Due"
    },
    {
      id: 6,
      name: "Institut EuropIA",
      description: "Institut EuropIA",
      image: "images/clients/europia.png",
      tags: [],
      twitter: "Institut EuropIA",
      alt: "Institut EuropIA"
    },
  ],
  'work': [
    {
      id: 0,
      name: "Dove Guardare",
      description: "Dove Guardare è un potente motore di ricerca di serie TV e Film in italiano. Scopri dove guardare i tuoi programmi preferiti!",
      image: "images/projects/doveguardare.png",
      tags: [],
      twitter: "https://doveguardare.it",
      alt: "Dove Guardare è un potente motore di ricerca di serie TV e Film in italiano. Scopri dove guardare i tuoi programmi preferiti!"
    },
    {
      id: 1,
      name: "Menti Brillanti",
      description:
          "\"Menti Brillanti\" è il podcast dedicato all'intelligenza artificiale che ti aprirà un mondo di conoscenze e innovazioni! ",
      image: "images/projects/menti.png",
      tags: [],
      twitter: "http://mentibrillanti.it",
      alt: "\"Menti Brillanti\" è il podcast dedicato all'intelligenza artificiale che ti aprirà un mondo di conoscenze e innovazioni!"
    },
    {
      id: 2,
      name: "AI Blog",
      description:
          "AI Blog è il mio blog, dove scrivo di intelligenza artificiale: ogni giorno approfondimenti e notizie sul mondo dell'AI.",
      image: "images/projects/aiblog.png",
      tags: [],
      twitter: "https://ai-blog.it",
      alt: "AI Blog è il mio blog, dove scrivo di intelligenza artificiale: ogni giorno approfondimenti e notizie sul mondo dell'AI."
    },
    {
      id: 3,
      name: "AI Chat",
      description:
          "AI Chat è un servizio di intelligenza artificiale che permette, in pochi passaggi, di creare un chatbot intelligente allenato sulle proprie info.",
      image: "images/projects/aichat.png",
      tags: [],
      twitter: "https://ai-chat.it",
      alt: "AI Chat è un servizio di intelligenza artificiale che permette, in pochi passaggi, di creare un chatbot intelligente allenato sulle proprie info."
    },
    {
      id: 4,
      name: "Overfitting.",
      description: "Overfitting è il podcast sull'AI realizzato insieme a Gerebros",
      image: "images/projects/Overfitting.png",
      tags: [],
      twitter: "https://www.youtube.com/@Overfitting.-mf2nv",
      alt: "Overfitting è il podcast sull'AI realizzato insieme a Gerebros"
    },
    {
      id: 5,
      name: "Algoretico",
      description: "Algoretico è una software house innovativa: realizziamo qualsiasi tipo di software e siamo specializzati in intelligenza artificiale.",
      image: "images/projects/algoretico.png",
      tags: [],
      twitter: "https://algoretico.it/",
      alt: "Algoretico è una software house innovativa: realizziamo qualsiasi tipo di software e siamo specializzati in intelligenza artificiale."
    },
    {
      id: 6,
      name: "AI Suite",
      description: `Oltre 70 strumenti di elaborazione del testo, AI Suite è lo strumento che ho realizzato per mettere a disposizione l'AI nella vita di tutti i giorni.`,
      image: "images/projects/aisuite.png",
      tags: [],
      twitter: "https://aisuite.it",
      alt: "Oltre 70 strumenti di elaborazione del testo, AI Suite è lo strumento che ho realizzato per mettere a disposizione l'AI nella vita di tutti i giorni."
    },
    {
      id: 7,
      name: "AI Uni",
      description: "AI Uni è la prima scuola di alta formazione dedicata esclusivamente al mondo dell'intelligenza artificiale. ",
      image: "images/projects/aiuni.png",
      tags: [],
      twitter: "https://ai-uni.it/",
      alt: "AI Uni è la prima scuola di alta formazione dedicata esclusivamente al mondo dell'intelligenza artificiale. "
    },
    {
      id: 8,
      name: "Ti Faremo Sapere",
      description: "Ti Faremo Sapere è una piattaforma di recruiting basata su AI che centralizza e valuta le candidature, accelerando il processo di assunzione e migliorando la selezione del personale.",
      image: "images/projects/tfs.png",
      tags: [],
      twitter: "https://tifaremosapere.it/",
      alt: "Ti Faremo Sapere è una piattaforma di recruiting basata su AI che centralizza e valuta le candidature, accelerando il processo di assunzione e migliorando la selezione del personale."
    },
    {
      id: 9,
      name: "Brainers",
      description: "Brainers è un ambizioso progetto di intelligenza artificiale per la creazione di Digital Humans.",
      image: "images/projects/brainers.png",
      tags: [],
      twitter: "https://brainers.ai/",
      alt: "Brainers è un ambizioso progetto di intelligenza artificiale per la creazione di Digital Humans. "
    },
    {
      id: 10,
      name: "NewsBuddy",
      description: "NewsBuddy è un'intelligenza artificiale che usa il linguaggio per raccogliere, capire e spiegare con una semplice chat le notizie.",
      image: "images/projects/newsbuddy.png",
      tags: [],
      twitter: "https://newsbuddy.it/",
      alt: "AI Uni è la prima scuola di alta formazione dedicata esclusivamente al mondo dell'intelligenza artificiale. "
    },
    {
      id: 11,
      name: "EnGarde!",
      description: "EnGarde! è il primo marketplace italiano di prodotti per la scherma che unisce i migliori prodotti con i migliori strumenti digitali.",
      image: "images/projects/engarde.png",
      tags: [],
      twitter: "https://engarde-fencing.com/",
      alt: "EnGarde! è il primo marketplace italiano di prodotti per la scherma che unisce i migliori prodotti con i migliori strumenti digitali."
    },
  ],
  'books': [
    {
      id: 0,
      name: "Come un'anima di Cristo",
      description: "I testi presenti in questo piccolo volume sono stati realizzati da un'intelligenza artificiale. È la prima attività di questo tipo in lingua italiana. ",
      image: "images/projects/anima.png",
      tags: [],
      twitter: "https://www.amazon.it/Come-unanima-Cristo-Poesie-create-ebook/dp/B08D6JVTPR",
      alt: "I testi presenti in questo piccolo volume sono stati realizzati da un'intelligenza artificiale. È la prima attività di questo tipo in lingua italiana. "
    },
    {
      id: 1,
      name: "La Matematica dell'Intelligenza Artificiale",
      description: "Questo libro è un saggio divulgativo che esplora il legame tra la matematica e l'Intelligenza Artificiale, analizzando come le proprietà matematiche influenzino i modelli algoritmici che sono la base della tecnologia AI.",
      image: "images/projects/matematica.png",
      tags: [],
      twitter: "https://www.amazon.it/dp/B0C3RTZ8PG",
      alt: "Questo libro è un saggio divulgativo che esplora il legame tra la matematica e l'Intelligenza Artificiale, analizzando come le proprietà matematiche influenzino i modelli algoritmici che sono la base della tecnologia AI."
    },
    {
      id: 2,
      name: "Alchimia",
      description: "Alchimia unisce storia, scienza e filosofia, esplorando il legame tra antica alchimia e moderna intelligenza artificiale, riflettendo sul futuro dell'umanità.",
      image: "images/projects/alchimia.png",
      tags: [],
      twitter: "https://santellionline.it/products/alchimia",
      alt: "Alchimia unisce storia, scienza e filosofia, esplorando il legame tra antica alchimia e moderna intelligenza artificiale, riflettendo sul futuro dell'umanità.",
    },
    {
      id: 3,
      name: "Dialoghi con una intelligenza artificiale",
      description: "Che cos'è l'intelligenza artificiale e in che modo ci riguarda?",
      image: "images/projects/dialoghi.png",
      tags: [],
      twitter: "https://www.amazon.it/Dialoghi-Intelligenza-Artificiale-Michele-Laurelli-ebook/dp/B08J3MRC7R",
      alt: "Che cos'è l'intelligenza artificiale e in che modo ci riguarda?"
    },
    {
      id: 4,
      name: "Intelligenza Artificiale e Criminologia",
      description: "Con l'avvento dell'AI (che Laurelli descrive molto bene soffermandosi nel campo del Processo Penale e dell'Investigazione), ormai una vecchia scienza è tramontata; ora lo scontro è tra intelligenza artificiale e intelligenza dell'uomo, in mezzo c'è la stupidità umana.",
      image: "images/projects/criminologia.png",
      tags: [],
      twitter: "https://www.mondadoristore.it/Intelligenza-artificiale-Michele-Laurelli-Saverio-Fortunato/eai978885548337/",
      alt: "Con l'avvento dell'AI (che Laurelli descrive molto bene soffermandosi nel campo del Processo Penale e dell'Investigazione), ormai una vecchia scienza è tramontata; ora lo scontro è tra intelligenza artificiale e intelligenza dell'uomo, in mezzo c'è la stupidità umana."
    }
  ]
};
