export default [
    {
        name: 'Intelligenza Artificiale',
        width: '93%',
    },
    {
        name: 'Project Management',
        width: '85%',
    },
    {
        name: 'Software Development',
        width: '90%',
    },
    {
        name: 'Web Development',
        width: '80%'
    },
]