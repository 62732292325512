import items from './items.js'
import tags from './tags.js'
import Experience from '../../Experience.js'

export default class CardsRender {
    constructor({ prefix = 'work', cards = [], hideText = false }) {
        this.prefix = prefix

        this.domElements = {
            renderContainer: document.getElementById(this.prefix + '-render-container')
        }

        this.experience = new Experience()
        this.sounds = this.experience.sounds
        this.cards = cards
        this.hideText = hideText

        this.items = items[this.prefix]
        this.tags = tags[this.prefix]

        this.renderItems()
    }

    renderItems() {
        this.items.forEach((item) => {
            this.domElements.renderContainer.insertAdjacentHTML('beforeend', `
            <div id="${this.prefix}-item-${item.id}" class="${this.prefix}-item-container column">
                <img class="${this.prefix}-item-image" src="${item.image}" alt="${item.alt}" height="300" width="334"/>
                ` + (this.hideText === true ? '' : `<div class="${this.prefix}-item-content-container">
                    <h3>${item.name}</h3>
                    <div class="${this.prefix}-item-tag-container row">
                        ${this.renderTags(item.tags)}
                    </div>
                    <span>${item.description}</span>
                </div>`) + `
                <div class="${this.prefix}-item-button-container row">
                    ${this.renderButtons(item)}
                </div>
                ${item.bannerIcons ? this.renderBanner(item) : ''}
            </div>
            `)

            this.addEventListenersToCard(item)
        })
    }

    renderBanner(item) {
        let content = ''

        content = `
            <div class="${this.prefix}-banner-container row center">
                ${item.bannerIcons.map(icon =>  {
                    return `<img src="${icon.src}" alt="${icon.alt}" height="64" width="64"/>`
                })}
                <span>Website Of<br>The Day</span>
            </div>
        `

        return content
    }

    renderButtons(item) {
        let content = ''

        if (item.github) {
            content = `
                <div id="${this.prefix}-item-gray-button-${item.id}" class="${this.prefix}-item-gray-button center gray-hover" ${item.liveview ? '' : 'style="width: 100%"'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"  class="code-icon">
                        <use href="#code-path"/>
                    </svg>
                 ${item.liveview ? '' : '<span>Source Code</span>'}
                </div>
                 ${item.liveview ? `<div id="${this.prefix}-item-orange-button-${item.id}" class="${this.prefix}-item-orange-button small-button center orange-hover">Live View</div>` : ''}
            `
        } else if (item.twitter) {
            content = `
            <div id="${this.prefix}-item-orange-button-${item.id}" class="${this.prefix}-item-orange-button small-button center orange-hover" style="width: 100%; margin: 0;">
                Scopri di più!
            </div>`
        } else {
            content = `
                <div id="${this.prefix}-item-gray-button-${item.id}" class="${this.prefix}-item-gray-button center" style="width: 100%; background: #a7adb8; cursor: unset;">
                    Work in progress
                </div>
            `
        }

        return content
    }

    renderTags(tags) {
        let contentToReturn = ''

        //get requested tag from tags.js file
        for (let i = 0; i < tags.length; i++) {
            contentToReturn += this.tags[tags[i]]
        }

        return contentToReturn
    }


    addEventListenersToCard(item) {
        const container = document.getElementById(this.prefix + '-item-' + item.id)

        // Inactive Container click
        container.addEventListener('click', () => {
            if (container.classList.contains(this.prefix + '-inactive-item-container') && document.getElementById(this.prefix + '-item-0').classList.contains(this.prefix + '-item-container-transition')) {
                this.cards.currentItemIndex = -item.id + (this.items.length - 1)
                if (this.cards.updatePositions !== undefined) {
                    this.cards.updatePositions()
                }
                this.sounds.play('buttonClick')
            }
        })

        if (item.github) {
            // Gray button click
            document.getElementById(this.prefix + '-item-gray-button-' + item.id).addEventListener('click', () => {
                window.open(item.github, '_blank').focus()
            })

            // orange button click
            if (item.liveview) {
                document.getElementById(this.prefix + '-item-orange-button-' + item.id).addEventListener('click', () => {
                    window.open(item.liveview, '_blank').focus()
                })
            }
        } else if (item.twitter) {
            // orange button click
            document.getElementById(this.prefix + '-item-orange-button-' + item.id).addEventListener('click', () => {
                window.open(item.twitter, '_blank').focus()
            })
        }
    }
} 